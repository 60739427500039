<!--
 * @Descripttion:
 * @version:
 * @Author: silva.yanhui
 * @Date: 2020-10-13 11:35:56
 * @LastEditors: Silva.yanhui
 * @LastEditTime: 2021-02-26 10:24:59
-->
<template>
  <div>
    <!-- /////////////////////////////////////////Navigation -->
    <nav
      id="menu"
      class="navbar navbar-default navbar-light   navbar-expand-lg fixed-top "
      :class="{ on: showNavBar }"
    >
      <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->

        <a class="navbar-brand" href="/">
          <span>欢涂居漆</span>
        </a>
        <!-- Collect the nav links, forms, and other content for toggling -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="navbar-nav ml-auto">
            <li
              v-for="(item, index) in routes"
              :key="index"
              class="nav-item   "
            >
              <router-link :to="item.path" class="nav-item btn-2 button trim">{{
                item.name
              }}</router-link>
            </li>
          </div>
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.container-fluid -->
    </nav>
    <!-- Navigation -->
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from 'vue'

import $ from 'jquery'
import { WOW } from 'wowjs'

export default {
  data() {
    return {
      wow: null,
      routes: []
    }
  },
  watch: {
    $route() {
      if (!/msie [6|7|8|9]/i.test(navigator.userAgent)) {
        this.$nextTick(() => {
          if (this.wow) this.wow = null
          this.wow = new WOW({
            live: false,
            animateClass: 'animated',
            offset: 20
          }).init()
        })
      } // wow.js
    }
  },
  created() {
    this.routes = this.$router.getRoutes().filter((i) => !i.meta.hidden)
    console.log(this.routes)
  },
  setup() {
    const state = reactive({
      showNavBar: false
    })
    const handleScroll = () => {
      const navHeight = 90
      state.showNavBar = $(window).scrollTop() > navHeight
    }
    const _bindScrollLinstener = () => {
      if (state.hasBindScroll) return
      state.hasBindScroll = true
      window.addEventListener('scroll', handleScroll)
    }
    const _fixedNavibarItemClick = () => {
      const a = document.querySelector('.navbar-toggler')
      $('.nav-item a').on('click', function() {
        a.click()
      })
    }
    onMounted(() => {
      _fixedNavibarItemClick()
      _bindScrollLinstener()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll')
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped type="text/css">
/* ---------------------------------------------------------------------------- */
/* ---------------------------------Header+Nav--------------------------------- */
/* ---------------------------------------------------------------------------- */
#menu {
  transition: all 0.8s;
  padding: 20px 0;
}

#menu.navbar-default {
  background-color: #2c3adc;

  border: none;
}

#menu a.navbar-brand {
  text-transform: uppercase;
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

#menu a.navbar-brand i.fa {
  color: $ActiveBtnColor;
}

#menu.navbar-default .navbar-nav > li > a {
  text-transform: uppercase;
  color: #d4d4d4;
  font-size: 14px;
  letter-spacing: 1px;
}

#menu.navbar-default .navbar-nav .router-link-active {
  color: #fff;
  font-weight: 700;
}

#menu.navbar-default .navbar-nav > li > a:hover {
  color: $ActiveBtnColor;
}

.on {
  background-color: #fff !important;

  padding: 0 !important;

  span {
    color: #333;
  }
  .router-link-active {
    color: #000 !important;
  }
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #fff !important;
  font-weight: 700;
  background-color: transparent;
}

.navbar-toggle {
  border-radius: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: $ActiveBtnColor;
  border: none;
}

.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #fff;
}

header {
  background: url(./image/header-bg.jpg) no-repeat center center;
  background-size: cover;
  color: #d9e0e2;
  position: relative;
  min-height: 420px;
}

header p {
  color: #d9e0e2;
  font-size: 19px;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.intro-text {
  position: relative;
  padding-top: 150px;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 50px;
}

.intro-text h1 {
  font-size: 60px;
  text-transform: uppercase;
  color: #d9e0e2;
  letter-spacing: 2px;
}
</style>
<style lang="scss" scoped type="text/css">
.btn-2 {
  letter-spacing: 0;
}

.btn-2:hover,
.btn-2:active {
  letter-spacing: 5px;
}

.btn-2:after,
.btn-2:before {
  backface-visibility: hidden;
  border: 1px solid rgba(#fff, 0);
  bottom: 0px;
  content: ' ';
  display: block;
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
}

.btn-2:hover:after,
.btn-2:hover:before {
  backface-visibility: hidden;
  border-color: #fff;
  transition: width 350ms ease-in-out;
  width: 100%;
}

.btn-2:hover:before {
  bottom: auto;
  top: 0;
  left: 0;
  width: 100%;
}

.svg-logo {
  display: inline-block;
  width: 4rem;
  height: 4rem;

  margin: -10px 0px 0 -10px;
}

.navbar-toggler {
  margin-right: 0.5rem;

  .navbar-toggler-icon {
    width: 1rem;
    height: 1rem;
  }
}
</style>
