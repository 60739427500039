<!--
 * @Descripttion:
 * @version:
 * @Author: silva.yanhui
 * @Date: 2020-10-13 14:08:51
 * @LastEditors: silva.yanhui
 * @LastEditTime: 2020-10-29 17:19:01
-->
<template>
  <!-- /////////////////////////////////////////Footer -->
  <footer>
    <div id="footer">
      <div class="container">
        <p>
          Copyright &copy;{{ new Date().getFullYear() }}.
          森王涂料有限公司版权所有.
          <a href="https://beian.miit.gov.cn" tartget="_blank"
            >粤ICP备14037974号</a
          >
        </p>
      </div>
    </div>
  </footer>
  <!-- Footer -->
</template>

<script>
import { reactive, toRefs } from 'vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {}
})
export default class extends Vue {
  setup() {
    const state = reactive({})
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped type="text/css">
/* ---------------------------------------------------------------------------- */
/* -------------------------------------Footer--------------------------------- */
/* ---------------------------------------------------------------------------- */
#footer {
  background: $basseBackColor;
  padding: 15px 0 10px 0;
  color: #42626b;
  text-align: center;
}
#footer p {
  margin-top: 10px;
  a {
    color: #42626b;
    &:hover {
      color: red;
    }
  }
}
</style>
