/*
 * @Descripttion:
 * @version:
 * @Author: silva.yanhui
 * @Date: 2020-10-13 10:23:34
 * @LastEditors: silva.yanhui
 * @LastEditTime: 2020-10-26 15:31:06
 */
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import 'nprogress/nprogress.css'
import router from './router'
/* plugins */
import 'jquery'
import Swiper, {
  Pagination,
  Navigation,
  Scrollbar,
  Keyboard,
  Thumbs
} from 'swiper'
Swiper.use([Pagination, Navigation, Scrollbar, Keyboard, Thumbs])

import '../public/js/bootstrap.min.js'

import 'wowjs/css/libs/animate.css'

import 'swiper/swiper-bundle.css'
// svg 图标
import '@/assets/svg-icons'
import SvgIcon from './components/svg-icon/index.vue' // svg组件
import Loading from './components/Loading/index.vue'
import { createPinia } from 'pinia'
import BaiduMap from 'vue-baidu-map-3x'
const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    ak: 'HS1yv0QIVGEHi327wDLa0LNM82t8EZsd'
    // v:'2.0',  // 默认使用3.0
    // type: 'WebGL' // ||API 默认API  (使用此模式 BMap=BMapGL)
  })

// register globally
app.component('svg-icon', SvgIcon)
app.component('Loading', Loading)

app.mount('#app')
