/*
 * @Descripttion:
 * @version:
 * @Author: silva.yanhui
 * @Date: 2020-10-13 10:23:34
 * @LastEditors: silva.yanhui
 * @LastEditTime: 2020-10-29 09:21:11
 */
import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'
import NProgress from 'nprogress'
import SecondLayout from '@/layout/secondLayout.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '首页',
    meta: {
      title: 'Welcome To Martian',
      subTitle: 'THE GREAT TRACK RECORD ON THE CHINESE MARKET'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home/index.vue')
  },
  {
    path: '/products',
    name: '产品',
    meta: {
      title: 'Games!',
      subTitle: ''
    },
    component: SecondLayout,
    children: [
      {
        path: '/products',
        meta: {
          hidden: true,
          title: ''
        },
        name: '产品',
        component: () =>
          import(/* webpackChunkName: "products" */ '../views/Games/index.vue')
      }
    ]
  },
  {
    path: '/colorcards',
    component: SecondLayout,
    name: '色卡系列',
    meta: {
      title: 'colorcards!',
      subTitle: ''
    },

    children: [
      {
        path: '/colorcards',
        name: '色卡系列',
        meta: {
          title: '色卡系列',
          hidden: true
        },
        component: () =>
          import(
            /* webpackChunkName: "colorcards" */ '../views/ColorCards/index.vue'
          )
      },
      {
        path: 'd/:id',
        name: '色卡系列详情',
        meta: {
          hidden: true,
          title: '色卡系列'
        },
        component: () =>
          import(
            /* webpackChunkName: "colorcardsDetail" */ '../views/ColorCards/detail.vue'
          )
      }
    ]
  },
  {
    path: '/samples',
    name: '样板工程',
    meta: {
      title: 'samples!',
      subTitle: ''
    },
    component: () =>
      import(
        /* webpackChunkName: "sampleProjects" */ '../views/SampleProjects/index.vue'
      )
  },

  {
    path: '/publish',
    name: '新闻',
    meta: {
      title: '新闻',
      subTitle: ''
    },
    // redirect: '/publish',
    component: SecondLayout,
    children: [
      {
        name: '新闻',
        path: '/publish',
        meta: {
          hidden: true,
          title: '新闻'
        },
        component: () =>
          import(
            /* webpackChunkName: "publishAll" */ '../views/Publish/index.vue'
          )
      },
      {
        path: 'detail/:id',
        name: '新闻详情',
        meta: {
          hidden: true,
          title: '新闻详情'
        },
        component: () =>
          import(
            /* webpackChunkName: "PublishDetail" */ '../views/Publish/detail.vue'
          )
      }
    ]
  },

  {
    path: '/about',
    name: '关于我们',
    meta: {
      title: ' About Us',
      subTitle: 'THE GREAT TRACK RECORD ON THE CHINESE MARKET'
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About/index.vue')
  },
  {
    path: '/contact',
    name: '联系我们',
    meta: {
      title: ' Contact Us !',
      subTitle: ''
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contact/index.vue')
  }
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory('/'),
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})
export default router
